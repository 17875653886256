import 'bootstrap/dist/css/bootstrap.min.css';  // Add this line at the top
import { Helmet } from 'react-helmet';
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Header from './components/Header';
import CourseDetails from './components/CourseDetails';
import SignUpForm from './components/SignUpForm';
import Testimonials from './components/Testimonials';
import Footer from './components/Footer';
import JavaScriptPractice from './components/JavaScriptPractice';
import Modal from './components/Modal';
import CongratsPage from './components/CongratsPage';
import CourseSyllabus from './components/CourseSyllabus';
import PrivacyPolicy from './components/PrivacyPolicy';
import AboutDevPursuit from './components/AboutDevPursuit'; 
import EbookDownloadPage from './components/EBookDownloadPage';
import MentorshipPitch from './components/MentorshipPitch';
import './App.css';


function App() {
  const [isModalOpen, setModalOpen] = useState(false);
  const [hasScrolledToSection, setHasScrolledToSection] = useState(false);

  const closeModal = () => {
    setModalOpen(false);
  };

  useEffect(() => {
    const handleScroll = () => {
      const courseSection = document.getElementById('course-syllabus-section');
      if (courseSection && !hasScrolledToSection) {
        const rect = courseSection.getBoundingClientRect();

        // Check if section is in view
        if (rect.top <= window.innerHeight) {
          setHasScrolledToSection(true); // Avoid re-triggering

          // Delay modal open with smooth transition
          setTimeout(() => {
            setModalOpen(true);
          }, 2000); // 2-second delay for smoother experience
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [hasScrolledToSection]);

  return (
    <Router>
      <div className="App">
        <Helmet>
          <link rel="canonical" href="https://www.devpursuit.com" /> {/* Default canonical for homepage */}
        </Helmet>

        {/* Header is always displayed */}
        <Header />

        {/* Modal that triggers based on scroll */}
        {isModalOpen && <Modal isOpen={isModalOpen} onClose={closeModal} />}

        <Routes>
          <Route
            path="/"
            element={
              <>
                <Helmet>
                  <title>DevPursuit | Home</title>
                  <link rel="canonical" href="https://www.devpursuit.com" />
                </Helmet>
                <CourseDetails />
                <SignUpForm />
                <section id="course-syllabus-section">
                  <CourseSyllabus />
                </section>
                <Testimonials />
              </>
            }
          />

        <Route
            path="/about"
            element={
              <>
                <Helmet>
                  <title>About DevPursuit | Meet Your Instructor</title>
                  <link rel="canonical" href="https://www.devpursuit.com/about" />
                </Helmet>
                <AboutDevPursuit />
              </>
            }
          />
          <Route
            path="/practice"
            element={
              <>
                <Helmet>
                  <title>JavaScript Practice | DevPursuit</title>
                  <link rel="canonical" href="https://www.devpursuit.com/practice" />
                </Helmet>
                <JavaScriptPractice />
              </>
            }
          />
          <Route
            path="/congratulations"
            element={
              <>
                <Helmet>
                  <title>Congratulations | DevPursuit</title>
                  <link rel="canonical" href="https://www.devpursuit.com/congratulations" />
                </Helmet>
                <CongratsPage />
              </>
            }
          />
          <Route
            path="/privacy-policy"
            element={
              <>
                <Helmet>
                  <title>Privacy Policy | DevPursuit</title>
                  <link rel="canonical" href="https://www.devpursuit.com/privacy-policy" />
                </Helmet>
                <PrivacyPolicy />
              </>
            }
          />

        <Route
            path="/download-ebook" 
            element={
              <>
                <Helmet>
                  <title>EBook Download  | DevPursuit</title>
                  <link rel="canonical" href="https://www.devpursuit.com/download-ebook" />
                </Helmet>
                <EbookDownloadPage/>
              </>
            }
          />


          <Route
            path="/mentorship" 
            element={
              <>
                <Helmet>
                  <title> 1:1 Session  | DevPursuit</title>
                  <link rel="canonical" href="https://www.devpursuit.com/mentorship" />
                </Helmet>
                <MentorshipPitch />
              </>
            }
          />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>

        {/* Footer is always displayed */}
        <Footer />
      </div>
    </Router>
  );
}

export default App;
