
import React, { useState } from 'react';
import '../App.css';

const SignUpForm = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [confirmation, setConfirmation] = useState('');
  const [isVisible, setIsVisible] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    console.log('API URL:', process.env.REACT_APP_API_URL);

    
    if (name && email) {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/sign-up`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ name, email }),
        });

        const responseBody = await response.json(); 

        if (!response.ok) {
          if (response.status === 400 && responseBody.error?.trim() === 'User already exists') {
            setConfirmation('This email is already registered. Please try another email.');
          } else {
            setConfirmation('There was an error signing up. Please try again later.');
          }
        } else {
          setConfirmation(`Thank you, ${name}! We've sent a confirmation email to ${email}.`);
        }
      } catch (error) {
        console.error('Error during signup:', error);
        setConfirmation('There was an error signing up. Please try again later.');
      }

      setName('');
      setEmail('');
      setIsVisible(true);

      setTimeout(() => {
        setIsVisible(false);
        setTimeout(() => setConfirmation(''), 500);
      }, 5000);
    }
  };

  return (
    <section className="sign-up">
      <h2>Sign Up for Early Access</h2>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          placeholder="Your Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
        />
        <input
          type="email"
          placeholder="Your Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <button type="submit">Sign Up</button>
      </form>
      {confirmation && (
        <p className={`confirmation-message ${isVisible ? 'visible' : ''}`}>
          {confirmation}
        </p>
      )}
    </section>
  );
};

export default SignUpForm;
