import React, { useState } from 'react';
import '../Mentorship.css'; 


const MentorshipfaqData = [
  {
    question: "Who is this mentorship for?",
    answer:
      "Entry-Level Learners: If you're new to coding or just getting started, this mentorship is perfect for building a strong foundation. \nIntermediate Learners: If you have some coding experience but need help expanding your skills, mastering new technologies, or tackling larger projects, this mentorship is ideal for you.",
  },
  {
    question: "What will I learn in these sessions?",
    answer:
      "Entry-Level: Fundamentals of programming (JavaScript, Python, HTML/CSS), building your first project or website, weekly code reviews, and debugging.\nIntermediate-Level: Advanced JavaScript, building full-stack apps, code optimization, best practices, real-world project development, and portfolio building.",
  },
  {
    question: "How do I book a session?",
    answer: "You can book a session directly on my website or contact me via email/social media to schedule a time that works for you.",
  },
  {
    question: "How much do the sessions cost?",
    answer:
      "Entry-Level Session: $50 per hour\nIntermediate Session: $75 per hour\n(Discounted packages available for multiple sessions)",
  },
  {
    question: "How long is each session?",
    answer: "Each session is 1 hour long, focused on tackling specific topics, reviewing your progress, and setting goals for the next week.",
  },
];

function MentorshipPitch() {
  const [openFAQ, setOpenFAQ] = useState(null);

  const toggleFAQ = (index) => {
    setOpenFAQ(openFAQ === index ? null : index);
  };

  return (
    <div className="mentorship-pitch">
      <header className="header">
        <h1 className="heading">🚀 Ready to Level Up Your Coding Skills?</h1>
        <img src="/Images/Private.png" alt="Coding" className="hero-image" />
        <p className="intro-text">
          Whether you’re just starting out or you’re looking to build more advanced projects, I offer personalized one-on-one mentorship
          designed to take you step-by-step through your coding journey. With a focus on <strong>goal-setting, progress tracking, and real-world projects</strong>,
          you’ll achieve <strong>milestones</strong>, <strong>master new skills</strong>, and gain the confidence to move forward in your coding career.
        </p>
      </header>

      <section className="what-i-offer">
        <h2 className="section-heading">What I Offer:</h2>
        <div className="mentorship-level">
          <h3>1. Entry-Level Mentorship (For Beginners)</h3>
          <ul>
            <li><strong>Define Goals</strong>: We’ll set clear, achievable goals based on your interests (e.g., building your first website, learning JavaScript).</li>
            <li><strong>Weekly Code Reviews</strong>: Get feedback on your code, solve problems together, and improve your coding style.</li>
            <li><strong>Achieve Milestones</strong>: Celebrate progress with tangible milestones (e.g., completing your first project, understanding key concepts).</li>
            <li><strong>Master New Skills and Projects</strong>: Learn to build real-world applications, create a portfolio, and gain hands-on experience.</li>
          </ul>
        </div>

        <div className="mentorship-level">
          <h3>2. Intermediate Mentorship (For Learners with Some Experience)</h3>
          <ul>
            <li><strong>Define Goals</strong>: We’ll identify your specific coding goals and tailor the sessions to help you meet them (e.g., learning frameworks like React, building a backend with Node.js).</li>
            <li><strong>Weekly Code Reviews</strong>: Dive deeper into your code to optimize and improve it, ensuring you are following best practices.</li>
            <li><strong>Achieve Milestones</strong>: Track your progress with bigger milestones, like building a full-stack application or creating an API.</li>
            <li><strong>Master New Skills and Projects</strong>: Master frameworks, libraries, or advanced coding concepts while working on real-world projects that will boost your portfolio.</li>
          </ul>
        </div>

        <h2 className="section-heading">Session Details</h2>
        <p><strong>Duration:</strong> 1-hour sessions</p>
        <p><strong>Format:</strong> One-on-one video call via Zoom (or your preferred platform)</p>
        <p><strong>Availability:</strong> Flexible scheduling based on your time zone</p>
        <p><strong>Pricing:</strong> Entry-Level Session: $50/hour, Intermediate Session: $75/hour</p>
      </section>

      <section className="Mentorshipfaq">
        <h2 className="Mentorship-section-heading">Frequently Asked Questions</h2>
        {MentorshipfaqData.map((Mentorshipfaq, index) => (
          <div key={index} className="Mentoshipfaq-item">
            <div className="Mentorshipfaq-question" onClick={() => toggleFAQ(index)}>
              <h3>{Mentorshipfaq.question}</h3>
            </div>
            {openFAQ === index && (
              <div className="faq-answer">
                <p>{Mentorshipfaq.answer}</p>
              </div>
            )}
          </div>
        ))}
      </section>
    </div>
  );
}

export default MentorshipPitch;
