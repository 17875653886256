import React from 'react';
import '../EbookDownloadPage.css'; 


function EbookDownloadPage() {
  return (
    <div className="ebook-download-page">
      <header className="ebook-header">
        <h1>Download Your Free Ebook: <em>Code That Shines</em></h1>
        <img className="ebook-img" src="/Images/DP_Ebook.png" alt="ebook-image" />
        <p className="subheading">10 Essential Tips for Writing Clean and Maintainable Code</p>
      </header>

      <section className="ebook-description">
        <p>
          This ebook is packed with practical tips and strategies that will help you write code that not only works but is also easy to maintain and scale. Learn best practices that will make your code clearer, more efficient, and enjoyable to work with.
        </p>
      </section>

      <section className="download-section">
        <h2>Get Your Copy Now!</h2>
        <a href="/Ebooks/Code_That_Shines_ebook.pdf" download="Code_That_Shines_ebook.pdf">
          <button className="download-button">Download the Ebook</button>
        </a>
      </section>
    </div>
  );
}

export default EbookDownloadPage;