import React from 'react';
import '../App.css';

const AboutDevPursuit = () => {
  return (
    <div className="about-container">
      {/* About DevPursuit */}
      <section className="about-devpursuit">
        <h2>Who We Are</h2>
        <p>
          At DevPursuit, we are dedicated to guiding aspiring developers from 
          their first steps in coding to becoming confident full-stack professionals. 
          We believe in creating a supportive, practical, and empowering learning 
          experience for everyone.
        </p>
        <p>
          Our core values:
        </p>
        <ul className="core-values">
          <li>💡 Empowering learners through practical skills</li>
          <li>🚀 Inspiring innovation and creativity</li>
          <li>🤝 Building a supportive and inclusive community</li>
        </ul>
      </section>

      {/* Meet Your Instructor */}
      <section className="meet-instructor">
        <h2>Meet Your Instructor</h2>
        <div className="instructor-details">
          <img
            src="/Images/Headshot.JPG"
            alt="Instructor"
            className="instructor-image"
          />
          <p>
            Hello! I'm <strong>Shaniece Crumpler</strong>, the founder and instructor behind DevPursuit. With over five years of 
            experience in the tech industry, I’ve been in your shoes—eager to learn, grow, and excel in software engineering. 
            My goal is to make learning accessible, enjoyable, and rewarding. Let’s embark on this journey together 
            and bring your coding dreams to life!
          </p>
        </div>
      </section>
    </div>
  );
};

export default AboutDevPursuit;
