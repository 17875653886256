import React from 'react';
import '../PrivacyPolicy.css';  

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy-container">
      <div className="privacy-policy-header">
        <h1>Privacy Policy for DevPursuit</h1>
        <p>Last updated: November 21, 2024</p>
      </div>

      <div className="privacy-policy-content">
        <p>
          This Privacy Policy describes Our policies and procedures on the
          collection, use and disclosure of Your information when You use the
          Service and tells You about Your privacy rights and how the law
          protects You.
        </p>
        <p>
          We use Your Personal data to provide and improve the Service. By using
          the Service, You agree to the collection and use of information in
          accordance with this Privacy Policy. This Privacy Policy has been created
          with the help of the Free Privacy Policy Generator.
        </p>

        <h2 className="section-title">Interpretation and Definitions</h2>
        <p className="section-subheading"><strong>Interpretation</strong></p>
        <p>
          The words of which the initial letter is capitalized have meanings defined
          under the following conditions. The following definitions shall have the
          same meaning regardless of whether they appear in singular or in plural.
        </p>

        <p className="section-subheading"><strong>Definitions</strong></p>
        <p>For the purposes of this Privacy Policy:</p>
        <ul className="definition-list">
          <li><strong>Account</strong> means a unique account created for You to access our Service or parts of our Service.</li>
          <li><strong>Affiliate</strong> means an entity that controls, is controlled by or is under common control with a party, where "control" means ownership of 50% or more of the shares, equity interest or other securities entitled to vote for election of directors or other managing authority.</li>
          <li><strong>Company</strong> refers to DevPursuit.</li>
          <li><strong>Cookies</strong> are small files that are placed on Your computer, mobile device or any other device by a website, containing the details of Your browsing history on that website among its many uses.</li>
          <li><strong>Country</strong> refers to: Texas, United States</li>
          <li><strong>Device</strong> means any device that can access the Service such as a computer, a cellphone or a digital tablet.</li>
          <li><strong>Personal Data</strong> is any information that relates to an identified or identifiable individual.</li>
          <li><strong>Service</strong> refers to the Website.</li>
          <li><strong>Service Provider</strong> means any natural or legal person who processes the data on behalf of the Company.</li>
          <li><strong>Usage Data</strong> refers to data collected automatically, either generated by the use of the Service or from the Service infrastructure itself.</li>
          <li><strong>Website</strong> refers to DevPursuit, accessible from www.devpursuit.com/</li>
          <li><strong>You</strong> means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.</li>
        </ul>

        <h2 className="section-title">Collecting and Using Your Personal Data</h2>
        <h3 className="section-subheading">Types of Data Collected</h3>
        <p><strong>Personal Data</strong></p>
        <p>
          While using Our Service, We may ask You to provide Us with certain
          personally identifiable information that can be used to contact or identify
          You. Personally identifiable information may include, but is not limited to:
        </p>
        <ul>
          <li>Email address</li>
          <li>First name and last name</li>
          <li>Usage Data</li>
        </ul>

        <h3 className="section-subheading">Usage Data</h3>
        <p>Usage Data is collected automatically when using the Service.</p>
        <p>
          Usage Data may include information such as Your Device's Internet Protocol
          address (e.g. IP address), browser type, browser version, the pages of our
          Service that You visit, the time and date of Your visit, the time spent on
          those pages, unique device identifiers and other diagnostic data.
        </p>

        <h2 className="section-title">Tracking Technologies and Cookies</h2>
        <p>
          We use Cookies and similar tracking technologies to track the activity on
          Our Service and store certain information. Tracking technologies used are
          beacons, tags, and scripts to collect and track information and to improve
          and analyze Our Service.
        </p>

        <p className="section-subheading"><strong>Cookies Policy</strong></p>
        <p>We use both Session and Persistent Cookies for the purposes set out below:</p>
        <ul className="cookie-list">
          <li><strong>Necessary / Essential Cookies</strong> - These Cookies are essential to provide You with services available through the Website and to enable You to use some of its features.</li>
          <li><strong>Cookies Policy / Notice Acceptance Cookies</strong> - These Cookies identify if users have accepted the use of cookies on the Website.</li>
          <li><strong>Functionality Cookies</strong> - These Cookies allow us to remember choices You make when You use the Website, such as remembering your login details or language preference.</li>
        </ul>

        <h2 className="section-title">Use of Your Personal Data</h2>
        <p>The Company may use Personal Data for the following purposes:</p>
        <ul>
          <li>To provide and maintain our Service.</li>
          <li>To manage Your Account.</li>
          <li>For the performance of a contract.</li>
          <li>To contact You for informational communications.</li>
          <li>For business transfers.</li>
          <li>For other purposes like data analysis and improving the Service.</li>
        </ul>

        <h2 className="section-title">Disclosure of Your Personal Data</h2>
        <p>The Company may disclose Your Personal Data in the good faith belief that such action is necessary to:</p>
        <ul>
          <li>Comply with a legal obligation</li>
          <li>Protect and defend the rights or property of the Company</li>
          <li>Protect the personal safety of Users of the Service or the public</li>
        </ul>

        <h2 className="section-title">Security of Your Personal Data</h2>
        <p>The security of Your Personal Data is important to Us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure.</p>

        <h2 className="section-title">Children's Privacy</h2>
        <p>Our Service does not address anyone under the age of 13. We do not knowingly collect personally identifiable information from anyone under the age of 13.</p>

        <h2 className="section-title">Google User Data Retention and Deletion</h2>
        <p><strong>Data Retention</strong>: If You grant the Company access to Your Google account (e.g., for authentication or integration with third-party services), we will retain Your Google user data only for as long as necessary to fulfill the purposes outlined in this Privacy Policy. Once no longer needed, Your Google user data will be securely deleted.</p>
        <p><strong>Deletion of Data</strong>: You may request the deletion of Your Google user data at any time. If You revoke access to Your Google account, we will delete Your Google user data from our systems unless retention is required for legal or business purposes.</p>

        <h2 className="section-title">Changes to this Privacy Policy</h2>
        <p>We may update Our Privacy Policy from time to time. We will notify You of any changes by posting the new Privacy Policy on this page.</p>

        <h2 className="section-title">Contact Us</h2>
        <p>If you have any questions about this Privacy Policy, You can contact us by email: <a href="mailto:info@devpursuit.com">info@devpursuit.com</a></p>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
