
import React, { useState, useEffect } from 'react';
import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/theme-monokai';
import 'ace-builds/src-noconflict/mode-javascript';
import Guidance from './Guidance';
import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Link } from 'react-router-dom';

import '../JavaScriptPractice.css';

const JavaScriptPractice = () => {
    const [code, setCode] = useState("");
    const [outputLog, setOutputLog] = useState([]);
    const [worker, setWorker] = useState(null);
    const [activeStep, setActiveStep] = useState(0);
    const [errorMessage, setErrorMessage] = useState(""); // Error message state
    const [isMobile, setIsMobile] = useState(false); // State for detecting mobile screen

    const navigate = useNavigate(); // Initialize useNavigate hook

    const exercises = [
        {
            id: 1,
            title: "Exercise 1: Simple Calculator (Addition Only)",
            starterCode: `
            function add(a, b) {
                // Your code here
            }
            const result = add(5, 5);
            console.log("The result is:", result); 
            `,
            expectedOutput: "The result is: 10",
            steps: [
                "Define two numbers (e.g., num1 and num2) and initialize them with numbers.",
                "Write a function 'add' that returns the sum of two numbers.",
                "Call the function with example numbers and log the result."
            ]
        },
        {
            id: 2,
            title: "Exercise 2: FizzBuzz",
            starterCode: `
            function fizzBuzz() {
                for (let i = 1; i <= 15; i++) {
                    // Add conditions to print "Fizz", "Buzz", or "FizzBuzz"
                }
            }
            fizzBuzz();
            `,
            expectedOutput: "1\n2\nFizz\n4\nBuzz\nFizz\n7\n8\nFizz\nBuzz\n11\nFizz\n13\n14\nFizzBuzz",
            steps: [
                "Write a loop to iterate from 1 to 15.",
                "Use conditional statements for 'Fizz', 'Buzz', and 'FizzBuzz'.",
                "Log the results."
            ]
        },
        {
            id: 3,
            title: "Exercise 3: Word Counter",
            starterCode: `
            function countWords(sentence) {
                // Split the sentence into words and return the length
            }
            const result = countWords("I love JavaScript");
            console.log("Word count:", result); 
            `,
            expectedOutput: "Word count: 3",
            steps: [
                "Define the function and add a parameter for the sentence.",
                "Split the sentence into words.",
                "Return the word count."
            ]
        }
    ];

    useEffect(() => {
        // Detect screen size on initial load and resize
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768); // Set mobile if width <= 768px
        };

        window.addEventListener('resize', handleResize);
        handleResize(); // Call once on load to check initial size

        const newWorker = new Worker('/codeWorker.js');
        setWorker(newWorker);

        newWorker.onmessage = (e) => {
            const { output, error } = e.data;
            const formattedOutput = error ? `Error: ${error}` : output;

            if (!error) {
                // Only log the output if it's correct
                if (validateOutput(formattedOutput.trim())) {
                    // Log the expected output before moving to the next step
                    setOutputLog([exercises[activeStep].expectedOutput]);

                    // Wait a moment before showing success and moving to the next step
                    setTimeout(() => {
                        alert("Correct! Moving to the next step.");
                        setOutputLog([]); // Clear output log after success
                        setActiveStep(prevStep => Math.min(prevStep + 1, exercises.length - 1));

                        if (activeStep === exercises.length - 1) {
                            navigate('/congratulations');  // Use navigate to redirect
                        }

                    }, 1000);
                } else {
                    setErrorMessage("Incorrect answer. Try again!");
                    setOutputLog([]); // Clear output log to avoid showing the expected output for wrong answers
                }
            } else {
                setErrorMessage(formattedOutput);
                setOutputLog([formattedOutput]); // Show error message in the output log
            }
        };

        return () => {
            window.removeEventListener('resize', handleResize); // Cleanup event listener
            newWorker.terminate();
        };
    }, [activeStep , navigate]);

    const runCode = () => {
        if (!code.trim()) {
            setErrorMessage("Please enter some code to run.");
            return;
        }

        setErrorMessage(""); // Clear previous error
        if (worker) {
            worker.postMessage(code);
        }
    };

    const validateOutput = (output) => {
        return output === exercises[activeStep].expectedOutput;
    };

    return (
        <div className="container">
            <div className="editor-container">
                <h1 className='editor_container_tite'>JavaScript Practice</h1>

                {isMobile && (
                    <div className="mobile-warning">
                        <p>
                            This editor is optimized for larger screens. For the best experience, we recommend using a tablet or desktop/laptop.
                        </p>
                    </div>
                )}
                
                <AceEditor
                    mode="javascript"
                    theme="monokai"
                    name="codeEditor"
                    value={code}
                    onChange={(newCode) => setCode(newCode)}
                    editorProps={{ $blockScrolling: true }}
                    fontSize={24}   // Font size of 24px
                    wrapEnabled={false}  // No wrapping (single-line display)
                    selectionStyle="line"  // Full line selection
                    style={{
                        height: '300px', 
                        width: '100%',
                        margin: 0,
                    }}
                    setOptions={{
                        showPrintMargin: false,
                        scrollMargin: [0, 0],  // Ensure no extra space at the top/bottom
                    }}
                />
                
                <button onClick={runCode} style={{ marginTop: '10px' , marginRight: '50%'}}>Run Code</button>
                
                <pre className="output">
                    {outputLog.map((line, index) => (
                        <div key={index}>{line}</div>
                    ))}
                </pre>
                {errorMessage && <div className="error-message">{errorMessage}</div>}

                <div className='backIcon' style={{ cursor: 'pointer' , display: 'flex', marginTop: '5%'}}>
                    <Link to="/">
                        <ArrowBackIcon style={{ fontSize: 24, marginRight: 8 }} /> 
                        <span style={{ fontSize: '16px', color: 'hotpink' , fontWeight: 'bold'}}>Back</span> 
                    </Link>
                </div>
            </div>
              
            <Guidance currentExerciseIndex={activeStep} /> {/* Pass only activeStep as currentExerciseIndex */}
        </div>
    );
};

export default JavaScriptPractice;
