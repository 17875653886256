
import React from 'react';
import '../JavaScriptPractice.css';

const Guidance = ({ currentExerciseIndex }) => {

    const exercises = [
        {
            id: 1,
            title: "Exercise 1: Simple Calculator (Addition Only)",
            expectedOutput: "The result is: 10",
            codeBreakdown: [
                {
                    description: "The function 'add' takes two parameters: a and b.",
                    action: "You need to write the code to add a and b together inside the function."
                },
                {
                    description: "After completing the function, it will return the sum of the two numbers.",
                    action: "You should use 'return a + b;' inside the function to get the sum."
                },
                {
                    description: "The result of calling add(5, 5) will be stored in the result variable.",
                    action: "You can test your function by calling it with different values and logging the result."
                }
            ]
        },
        {
            id: 2,
            title: "Exercise 2: FizzBuzz",
            expectedOutput: "1\n2\nFizz\n4\nBuzz\nFizz\n7\n8\nFizz\nBuzz\n11\nFizz\n13\n14\nFizzBuzz",
            codeBreakdown: [
                {
                    description: "The function 'fizzBuzz' needs a loop that runs from 1 to 15.",
                    action: "Use a for loop to iterate through numbers from 1 to 15."
                },
                {
                    description: "Add conditions to print 'Fizz' for multiples of 3, 'Buzz' for multiples of 5, and 'FizzBuzz' for multiples of both.",
                    action: "Use 'if' statements to check the number's divisibility by 3, 5, or both."
                },
                {
                    description: "Log the result to the console for each iteration.",
                    action: "Inside the loop, use 'console.log()' to print the results."
                }
            ]
        },
        {
            id: 3,
            title: "Exercise 3: Word Counter",
            expectedOutput: "Word count: 3",
            codeBreakdown: [
                {
                    description: "The function 'countWords' takes a sentence as an argument.",
                    action: "You need to split the sentence into words using the 'split()' method."
                },
                {
                    description: "Use the length of the resulting array to get the word count.",
                    action: "Return the length of the array from the split sentence."
                },
                {
                    description: "Test the function with a sample sentence and log the result.",
                    action: "For example, 'I love JavaScript' should return 3."
                }
            ]
        }
    ];

    const currentExercise = exercises[currentExerciseIndex];

    return (
        <div className="guidance">
            <h2>{currentExercise.title}</h2>

            <h3>Code Breakdown</h3>
            <ul>
                {currentExercise.codeBreakdown.map((item, index) => (
                    <li key={index}>
                        <strong>{item.description}</strong><br />
                        <em>{item.action}</em>
                    </li>
                ))}
            </ul>

            <h3>Expected Output</h3>
            <p>{currentExercise.expectedOutput}</p>
        </div>
    );
};

export default Guidance;
